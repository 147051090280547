import httpClient from "./httpClient";
const monthlyReportAgents = (
  days,
  departments,
  agents,
  dossiers,
  displayParameter
) =>
  httpClient.get("/api/monthlyReportAgents", {
    params: { days, departments, agents, dossiers, displayParameter },
  });
export { monthlyReportAgents };
