import httpClient from "./httpClient";
const monthlyReportExport = (
  days,
  departments,
  agents,
  dossiers,
  displayParameter
) =>
  httpClient.get("/api/monthlyReportExport", {
    responseType: "blob",
    params: { days, departments, agents, dossiers, displayParameter },
  });
export { monthlyReportExport };
