<template>
  <v-container class="pa-16">
    <v-card>
      <v-card-title class="justify-space-between">
        <span class="headline">Selectionnez l'intervalle de dates</span>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" class="mt-3 mb-10">
          <Button
            action_name="Calendrier"
            color="#86CC26"
            style="width: 180px"
            @clicked="togglePicker"
          >
            <template v-slot:button-content>
              <v-icon color="white">mdi-calendar</v-icon>
            </template>
          </Button>
        </v-row>
        <v-row justify="center" class="mt-5 mb-20">
          <v-date-picker
            v-if="isClicked"
            v-model="dates"
            range
            locale="fr"
            year-icon="mdi-calendar-blank"
            color="#86bc26"
            :allowed-dates="allowedDates"
            @input="onDateSelected"
          ></v-date-picker>
        </v-row>
        <v-row justify="center">
          <div class="filter-block">
            <div>
              <template v-for="(department, index) in selectedDepartments">
                <v-chip
                  class="ma-1"
                  :key="department"
                  close
                  @click:close="handleChipDelete(index, 'department')"
                  >Département: {{ department }}</v-chip
                >
              </template>
              <template v-for="(agent, index) in selectedUsers">
                <v-chip
                  class="ma-1"
                  :key="agent"
                  close
                  @click:close="handleChipDelete(index, 'agent')"
                  >Agent: {{ getAgentName(agent) }}</v-chip
                >
              </template>
              <template v-for="(dossier, index) in selectedDossiers">
                <v-chip
                  class="ma-1"
                  :key="dossier"
                  close
                  @click:close="handleChipDelete(index, 'dossier')"
                  >Dossier: {{ getDossierName(dossier) }}</v-chip
                >
              </template>
              <template v-if="selectedDisplayParameter">
                <v-chip
                  class="ma-1"
                  close
                  @click:close="handleExactChipDelete('parameter')"
                  >Paramètre d'affichage:{{ selectedDisplayParameter }}</v-chip
                >
              </template>
            </div>
            <div>
              <v-chip class="chip-delete">
                <v-icon @click="filterDialog = true" outlined
                  >mdi-filter</v-icon
                >
              </v-chip>
            </div>
          </div>
        </v-row>

        <v-dialog v-model="filterDialog" max-width="836px">
          <v-card>
            <v-card-title class="justify-space-between">
              <span class="headline">Filtre</span>
              <v-btn
                color="black"
                dark
                icon
                right
                @click="filterDialog = false"
              >
                <v-icon color="#B2BBAB">mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-autocomplete
                      v-model="selectedDepartments"
                      :items="departments"
                      chips
                      deletable-chips
                      multiple
                      clearable
                      hint="Choisissez le/les départements"
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-autocomplete
                      v-model="selectedUsers"
                      :items="activeUsers"
                      item-text="name"
                      item-value="id"
                      chips
                      deletable-chips
                      multiple
                      clearable
                      hint="Choisissez le/les agents"
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-autocomplete
                      v-model="selectedDossiers"
                      :items="dossiers"
                      item-text="name"
                      item-value="id"
                      chips
                      deletable-chips
                      multiple
                      clearable
                      hint="Choisissez le/les dossiers"
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-autocomplete
                      v-model="selectedDisplayParameter"
                      :items="parameters"
                      item-text="name"
                      item-value="id"
                      chips
                      deletable-chips
                      clearable
                      hint="Grouper les données par"
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <span class="mb-5" style="color:#B2BBAB" @click="handleReset"
                >Réinitialiser</span
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <Button
          action_name="Exporter"
          color="#86CC26"
          @clicked="exportRapportMensuel"
          style="width: 150px"
          class="ml-5 mr-5 mb-5 mt-10"
          :loading="loadingExport"
        >
          <template v-slot:button-content>
            <v-img :src="require('@/assets/export.svg')" alt="humberger" />
          </template>
        </Button>
        <Button
          action_name="Afficher"
          color="#86CC26"
          @clicked="monthlyReportAgents"
          style="width: 150px"
          class="ml-5 mr-5 mb-5 mt-10"
          :loading="loadingDisplay"
        >
          <template v-slot:button-content>
            <v-icon color="white">mdi-eye</v-icon>
          </template>
        </Button>
      </v-card-actions>
    </v-card>
    <v-card v-if="displayCard" class="mt-5">
      <v-card-title class="justify-space-between">
        <span class="headline"
          >Rapport des ressources de {{ sortedDates()[0] }} à
          {{ sortedDates()[1] }}</span
        >
        <v-btn color="black" dark icon right @click="close">
          <v-icon color="#B2BBAB">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" class="mt-5">
          <div class="table-container">
            <table class="custom-table">
              <thead>
                <tr>
                  <th
                    v-for="cell in tableData[0]['dataHeaderTable'].slice(0, -1)"
                    :key="cell"
                  >
                    {{ cell }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowIndex) in tableData[0]['dataBodyTable']"
                  :key="rowIndex"
                >
                  <td
                    v-for="(item, cellIndex) in row.slice(0, -1)"
                    :key="cellIndex"
                  >
                    {{ item }}

                    <v-progress-linear
                      v-if="cellIndex === 0 && row[row.length - 1] != ''"
                      :value="row[row.length - 1]"
                      height="15"
                      :color="getProgressColor(row[row.length - 1])"
                      rounded
                    >
                      <strong>{{ row[row.length - 1] }}%</strong>
                    </v-progress-linear>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item, cellIndex) in tableData[0][
                      'dataFooterTable'
                    ].slice(0, -1)"
                    :key="cellIndex"
                  >
                    {{ item }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import { Button } from "@/components/base";
import { monthlyReportExport } from "@/services/exportMonthlyReport.api";
import { monthlyReportAgents } from "@/services/monthlyReportAgents.api";
import { getDepartments } from "@/services/getDepartments.api";
import { getActiveUsers } from "@/services/getActiveUsers.api";
import { getActiveDossiers } from "@/services/getActiveDossiers.api";
export default {
  components: { Button },
  data: () => ({
    displayCard: false,
    year: "2024",
    month: "1",
    loadingExport: false,
    loadingDisplay: false,
    selectedDate: new Date().toISOString().substr(0, 7),
    selectedYear: new Date().getFullYear(),
    selectedMonth: new Date().getMonth() + 1,
    tableData: {
      0: { dataHeaderTable: [], dataBodyTable: [], dataFooterTable: [] },
    },
    selectedDepartments: [],
    departments: [],
    selectedUsers: [],
    activeUsers: [],
    dates: [],
    datesSelected: false,
    snackbar: false,
    text: "",
    timeout: 2000,
    dossiers: [],
    selectedDossiers: [],
    parameters: ["Jour", "Mois","Année"],
    selectedDisplayParameter: null,
    filterDialog: false,
    isClicked: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    togglePicker() {
      this.isClicked = !this.isClicked;
    },
    exportRapportMensuel() {
      if (this.datesSelected) {
        this.loadingExport = true;
        const days = this.dates;
        const departments = this.selectedDepartments;
        const agents = this.selectedUsers;
        const dossiers = this.selectedDossiers;
        let displayParameter = this.selectedDisplayParameter;
        if (!displayParameter) {
          displayParameter = "Jour";
        }
        monthlyReportExport(
          days,
          departments,
          agents,
          dossiers,
          displayParameter
        )
          .then((response) => {
            this.loadingExport = false;

            const blob = new Blob([response.data], { type: "application/csv" });
            const link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = `rapport_des_ressources.csv`;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
          })
          .catch((error) => {
            this.handleFail(error);
          });
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "Il faut selectionner l'intervalle de dates",
        });
      }
    },
    allowedDates(date) {
      const [year, month, day] = date.split("-");
      const currentDate = new Date();
      return (
        parseInt(year) < currentDate.getFullYear() ||
        (parseInt(year) === currentDate.getFullYear() &&
          (parseInt(month) < currentDate.getMonth() + 1 ||
            (parseInt(month) === currentDate.getMonth() + 1 &&
              parseInt(day) <= currentDate.getDate())))
      );
    },
    monthlyReportAgents() {
      if (this.datesSelected) {
        this.loadingDisplay = true;
        const days = this.dates;
        const departments = this.selectedDepartments;
        const agents = this.selectedUsers;
        const dossiers = this.selectedDossiers;
        let displayParameter = this.selectedDisplayParameter;
        if (!displayParameter) {
          displayParameter = "Jour";
        }
        monthlyReportAgents(
          days,
          departments,
          agents,
          dossiers,
          displayParameter
        )
          .then((response) => {
            this.loadingDisplay = false;
            this.tableData = Object.values(response.data);
            this.displayCard = true;
          })
          .catch((error) => {
            this.handleFail(error);
          });
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "Il faut selectionner l'intervalle de dates",
        });
      }
    },
    close() {
      this.displayCard = false;
      this.dates = [];
    },
    initialize() {
      this.getDepartments();
      this.getActiveUsers();
      this.getActiveDossiers();
    },
    getDepartments() {
      getDepartments().then((response) => {
        this.departments = response.data.departments;
      });
    },
    getActiveUsers() {
      getActiveUsers().then((response) => {
        this.activeUsers = response.data.users;
      });
    },
    getActiveDossiers() {
      getActiveDossiers().then((response) => {
        this.dossiers = response.data.dossiers;
      });
    },
    onDateSelected() {
      this.datesSelected = this.dates && this.dates.length === 2;
    },
    sortedDates() {
      return [...this.dates].sort();
    },
    handleFail(error) {
      this.text = error;
      this.snackbar = true;
    },
    getProgressColor(value) {
      if (value == 0) {
        return "red";
      } else if (value > 0 && value < 100) {
        return "#F59E0B";
      } else if (value == 100) {
        return "#86cc26";
      } else if (value > 100) {
        return "#66C0BC";
      }
    },
    handleReset() {
      this.selectedDepartments = [];
      this.selectedUsers = [];
      this.selectedDossiers = [];
      this.selectedDisplayParameter = null;
    },
    handleChipDelete(index, key) {
      switch (key) {
        case "department":
          this.selectedDepartments.splice(index, 1);
          break;
        case "agent":
          this.selectedUsers.splice(index, 1);
          break;
        case "dossier":
          this.selectedDossiers.splice(index, 1);
          break;
      }
    },
    handleExactChipDelete(key) {
      switch (key) {
        case "parameter":
          this.selectedDisplayParameter = null;
          break;
      }
    },
    getAgentName(agentId) {
      const agent = this.activeUsers.find((user) => user.id === agentId);
      return agent ? agent.name : "";
    },

    getDossierName(dossierId) {
      const dossier = this.dossiers.find((projet) => projet.id === dossierId);
      return dossier ? dossier.name : "";
    },
  },
};
</script>
<style scoped>
.table-container {
  max-width: 100%;
  overflow: auto;
  max-height: 600px;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  color: black;
  font-size: 13px;
  white-space: nowrap;
}
.custom-table th {
  color: white;
  background-color: #86cc26;
  font-size: 15px;
  width: 120px;
  position: sticky;
  top: 0;
  z-index: 3;
}
.custom-table tbody tr:last-child td {
  font-weight: bold;
  font-size: 14px;
}
.custom-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff;
}
.custom-table th:first-child {
  position: sticky;
  left: 0;
  z-index: 4;
  width: 150px;
}
</style>
